<template>
  <div class="thing_goods_list">
    <el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
							<el-input v-model="goods_name" placeholder="请输入名称" clearable></el-input>
						</el-form-item>
            <el-form-item>
              <el-input v-model="goods_sn" placeholder="请输入编号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <treeselect 
                v-model="category_id" 
                :options="category_list" 
                :normalizer="normalizer" 
                :show-count="true" 
                placeholder="请选择物料分类" 
              />
            </el-form-item>
            <el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
              <el-button type="primary" icon="el-icon-plus" @click="addGoods">新增商品</el-button>
						</el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table 
        id="goodsListTable"
        :data="list"
        @select="select"
        @select-all="selectAll" 
				style="width: 100%"
				:header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="编号" prop="goods_sn"></el-table-column>
        <el-table-column label="名称" prop="goods_name"></el-table-column>
        <el-table-column label="分类" prop="category_name"></el-table-column>
        <el-table-column label="原价" prop="original_price"></el-table-column>
        <el-table-column label="价格" prop="shop_price"></el-table-column>
        <el-table-column label="市场价" prop="market_price"></el-table-column>
        <el-table-column label="是否上架" prop="is_on_sale">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.is_on_sale">是</span>
              <span v-else>否</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="库存" prop="goods_number"></el-table-column>
        <el-table-column label="单位" prop="unit"></el-table-column>
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <div style="text-align: right;">
              <el-button size="mini" type="success" plain @click="attr(scope.row.goods_id)" v-if="scope.row.attrtype">商品属性</el-button>
              <el-button size="mini" type="primary" plain @click="edit(scope.row.goods_id)">编辑</el-button>
              <el-button size="mini" type="danger" plain @click="remove(scope.row.goods_id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
			</el-table>
      <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components:{
    Pagination,Treeselect
  },
  data () {
    return {
      category_list: [],
      queryInfo: {
        pagenum: 1,
        pagesize: 10,
      },
      total:10,

      goods_name:'',
      goods_sn:'',
      category_id: null,

      chooseList:[],
      list:[]
    }
  },
  created() {
		if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
      this.getCategoryList();
			this.getList();
		}
	},
  methods: {
    getList(){
			var url = 'thing/goods_list';
			let params = {
        goods_name: this.goods_name,
        goods_sn: this.goods_sn,
				category_id: this.category_id,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					this.total = res.total;
					this.list = res.list;
				} else {
					this.$message.error(res.msg);
				}
			}).catch((err) => {
				this.$message.error('网络错误');
			});
    },
    getCategoryList(){
      var url = 'thing/category_list';
      let params = {};
      this.fd_post(url, params).then((res) => {
        // console.log(res);
        if(res.status) {
          this.category_list = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    normalizer(node) {
      // console.log(node);
      if(node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.category_id,
        label: node.category_name,
        children: node.children,
      };
    },
    //查询
    search(){
      this.getList();
    },
    // 重置
    reset() {
      this.goods_name = "";
      this.goods_sn = "";
      this.category_id = null;
      this.getList();
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum=1
      this.queryInfo.pagesize=newSize
      this.getList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum=newPage
      this.getList();
    },
    //单选
    select(selection,row){
      this.chooseList=selection
      console.log(this.chooseList);
    },
    //全选
    selectAll(selection){
      this.chooseList=selection
      console.log(this.chooseList);
    },
    //新增商品
    addGoods() {
      this.$router.push('/thing_goods_add')
    },
    edit(goods_id) {
      this.$router.push({path:'/thing_goods_add',query: {goods_id: goods_id}})
    },
    attr(goods_id) {
      this.$router.push({path:'/thing_goods_attr_setting',query: {goods_id: goods_id}})
    }
  },
}
</script>

<style>
  .thing_goods_list .vue-treeselect__placeholder, .vue-treeselect__single-value{
    line-height: 40px;
  }
  .thing_goods_list .vue-treeselect__control{
    width: 200px;
  }
  .thing_goods_list{

  }
</style>